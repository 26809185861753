const data = JSON.stringify({
  total_pages: 5,
  pages: [
    {
      id: 1,
      page_nr: 1,
      subtitle: 'Typische Symptome',
      description: 'Bitte geben Sie an, ob Sie unten genannte Symptome innerhalb der letzten 24 Stunden bemerkt haben, und bewerten Sie bitte deren Intensität:',
      questions: [
        {
          id: 1,
          nr: 1,
          show_id: true,
          question: "Häufiges Wasserlassen mit geringen Urinportionen (wiederholte WC-Besuche)",
          answers: [
            {
              id: 1,
              desc: "bis 4‐mal täglich",
              name: "Nein",
              points: 0
            },
            {
              id: 2,
              desc: "5‐6‐mal täglich",
              name: "Ja, etwas öfter als sonst",
              points: 1
            },
            {
              id: 3,
              desc: "7‐8‐mal täglich",
              name: "Ja, merklich öfter",
              points: 2
            },
            {
              id: 4,
              desc: "9‐10‐mal täglich",
              name: "Ja, sehr oft",
              points: 3
            }
          ]
        },
        {
          id: 2,
          nr: 2,
          show_id: true,
          question: "Starker, unwillkürlicher Harndrang",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Nein",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Ja, wenig",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Ja, mäßig",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Ja, stark",
              points: 3
            }
          ]
        },
        {
          id: 3,
          nr: 3,
          show_id: true,
          question: "Schmerzen und Brennen beim Wasserlassen",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Nein",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Ja, wenig",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Ja, mäßig",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Ja, stark",
              points: 3
            }
          ]
        },
        {
          id: 4,
          nr: 4,
          show_id: true,
          question: "Gefühl einer unvollständigen Harnblasenentleerung",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Nein",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Ja, wenig",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Ja, mäßig",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Ja, stark",
              points: 3
            }
          ]
        },
        {
          id: 5,
          nr: 5,
          show_id: true,
          question: "Schmerzen oder Beschwerden (unangenehmes Druckgefühl) im Unterbauch oder Beckenbereich",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Nein",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Ja, wenig",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Ja, mäßig",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Ja, stark",
              points: 3
            }
          ]
        },
        {
          id: 6,
          nr: 6,
          show_id: true,
          question: "Sichtbares Blut im Urin",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Nein",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Ja, wenig",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Ja, mäßig",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Ja, stark",
              points: 3
            }
          ]
        },
      ]
    },
    {
      id: 2,
      page_nr: 2,
      subtitle: 'Differentialdiagnose',
      description: 'Bitte geben Sie an, ob Sie unten genannte Symptome innerhalb der letzten 24 Stunden bemerkt haben, und bewerten Sie bitte deren Intensität:',
      questions: [
        {
          id: 7,
          nr: 7,
          show_id: true,
          question: "Schmerzen in der Lendengegend (Flanke) (oft einseitig - auf einer Seite)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Nein",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Ja, wenig",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Ja, mäßig",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Ja, stark",
              points: 3
            }
          ]
        },
        {
          id: 8,
          nr: 8,
          show_id: true,
          question: "Neuer oder zunehmender Ausfluss aus der Scheide",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Nein",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Ja, wenig",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Ja, mäßig",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Ja, stark",
              points: 3
            }
          ]
        },
        {
          id: 9,
          nr: 9,
          show_id: true,
          question: "Eitriger Ausfluss aus der Harnröhre (unabhängig vom Wasserlassen)",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Nein",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Ja, wenig",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Ja, mäßig",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Ja, stark",
              points: 3
            }
          ]
        },
        {
          id: 10,
          nr: 10,
          show_id: true,
          question: "Erhöhte Körpertemperatur (über 37,5°C) / Schüttelfrost. Wenn Sie Temperatur gemessen haben, geben Sie diese bitte an",
          answers: [
            {
              id: 1,
              desc: "≤37.5 °C",
              name: "Nein",
              points: 0
            },
            {
              id: 2,
              desc: "37.6-37.9 °C",
              name: "Ja, wenig",
              points: 1
            },
            {
              id: 3,
              desc: "38.0-38.9 °C",
              name: "Ja, mäßig",
              points: 2
            },
            {
              id: 4,
              desc: "≥39.0 °C",
              name: "Ja, stark",
              points: 3
            }
          ]
        },
      ]
    },
    {
      id: 3,
      page_nr: 3,
      subtitle: 'Lebensqualität',
      description: '',
      questions: [
        {
          id: 11,
          nr: 11,
          show_id: true,
          question: "Bitte geben Sie an, wie stark ausgeprägt die durch die oben genannten Symptome hervorgerufenen Beschwerden innerhalb der letzten 24 Stunden waren",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Keine Beschwerden (keine Symptome, fühle mich wie immer)",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Geringe Beschwerden (fühle mich etwas unwohler als sonst)",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Starke Beschwerden (fühle mich merklich schlechter als sonst)",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Sehr starke Beschwerden (fühle mich schrecklich)",
              points: 3
            }
          ]
        },
        {
          id: 12,
          nr: 12,
          show_id: true,
          question: "Bitte geben Sie an, wie weit die oben genannten Symptome Ihre alltägliche Aktivität / Leistungsfähigkeit innerhalb der letzten 24 Stunden beeinträchtigt haben",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Überhaupt nicht beeinträchtigt (arbeite wie an gewöhnlichen Tagen, ohne Beschwerden)",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Ein wenig beeinträchtigt (wegen der Symptome arbeite ich etwas weniger)",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Bedeutend beeinträchtigt (alltägliche Arbeit ist anstrengend geworden)",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Stark beeinträchtigt (ich kann praktisch nicht arbeiten)",
              points: 3
            }
          ]
        },
        {
          id: 13,
          nr: 13,
          show_id: true,
          question: "Bitte geben Sie an, wie weit die oben genannten Symptome Ihre gesellschaftlichen Aktivitäten (Besuche machen, sich mit Freunden treffen usw.) innerhalb der letzten 24 Stunden beeinträchtigt haben",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Überhaupt nicht beeinträchtigt (es hat sich nichts geändert, ich lebe so wie vorher)",
              points: 0
            },
            {
              id: 2,
              desc: "",
              name: "Ein wenig beeinträchtigt (eine geringe Reduzierung der Aktivität)",
              points: 1
            },
            {
              id: 3,
              desc: "",
              name: "Bedeutend beeinträchtigt (viel weniger aktiv, bleibe mehr zu Hause)",
              points: 2
            },
            {
              id: 4,
              desc: "",
              name: "Sehr stark beeinträchtigt (schrecklich, kann das Haus praktisch nicht verlassen)",
              points: 3
            }
          ]
        },
      ]
    },
    {
      id: 4,
      page_nr: 4,
      subtitle: 'Begleitumstände',
      description: 'Bitte geben Sie an, ob zum Zeitpunkt des Ausfüllens des Fragenbogens bei Ihnen folgendes zutrifft:',
      questions: [
        {
          id: 14,
          nr: 14,
          show_id: true,
          question: "Menstruation (Regel) ?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Nein",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "Ja",
              points: "yes"
            }
          ]
        },
        {
          id: 15,
          nr: 15,
          show_id: false,
          question: "Prämenstruelle Beschwerden (Beschwerden in der Zeit vor der Regel) ?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Nein",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "Ja",
              points: "yes"
            }
          ]
        },
        {
          id: 16,
          nr: 16,
          show_id: false,
          question: "Klimakterisches Syndrom (Beschwerden in den Wechseljahren) ?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Nein",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "Ja",
              points: "yes"
            }
          ]
        },
        {
          id: 17,
          nr: 17,
          show_id: false,
          question: "Schwangerschaft?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Nein",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "Ja",
              points: "yes"
            }
          ]
        },
        {
          id: 18,
          nr: 18,
          show_id: false,
          question: "Zuckerkrankheit?",
          answers: [
            {
              id: 1,
              desc: "",
              name: "Nein",
              points: "no"
            },
            {
              id: 2,
              desc: "",
              name: "Ja",
              points: "yes"
            }
          ]
        }
      ]
    },
    {
      id: 5,
      page_nr: 5,
      subtitle: '',
      description: ''
    }
  ],
})

export default data
